import React from 'react'
import {Text} from '@primer/react-brand'
import styles from './Ticker.module.scss'

// eslint-disable-next-line import/extensions
import eventData from '../../content/events.json'
const events = eventData.events

export const Ticker = () => {
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)

  // Hide past and global events
  const filteredEvents = events.filter(event => {
    const eventDate = new Date(event.date)
    eventDate.setHours(0, 0, 0, 0) // makes sure event is shown until the end of the day

    if (event.region !== 'Global') {
      if (event.date === 'Coming soon') {
        return true
      } else if (eventDate >= currentDate) {
        return true
      }
    }

    return false
  })

  // Calculate animation speed based on number of events
  const animationSpeed = filteredEvents.length * 6

  // Minimum number of events to show in ticker
  const minEvents = 5

  return (
    <>
      {filteredEvents.length >= minEvents ? (
        <section className={styles.Ticker}>
          <div className={styles['Ticker-container']}>
            <div className={styles['Ticker-scroll']} style={{animationDuration: `${animationSpeed}s`}}>
              {filteredEvents.map((event, index) => (
                <React.Fragment key={index}>
                  <Text as="span" className={styles['Ticker-item']}>
                    {event.city}
                  </Text>
                  <Text as="span" className={styles['Ticker-item']}>
                    •
                  </Text>
                </React.Fragment>
              ))}
            </div>

            {/* Render twice so animation loops seamlessly */}
            <div className={styles['Ticker-scroll']} style={{animationDuration: `${animationSpeed}s`}}>
              {filteredEvents.map((event, index) => (
                <React.Fragment key={index}>
                  <Text as="span" className={styles['Ticker-item']}>
                    {event.city}
                  </Text>
                  <Text as="span" className={styles['Ticker-item']}>
                    •
                  </Text>
                </React.Fragment>
              ))}
            </div>
          </div>
        </section>
      ) : (
        // Fallback in case there are not enough events
        <div className={styles['Ticker-fallback']}></div>
      )}
    </>
  )
}
