import React, {useCallback} from 'react'
import {Image} from '@primer/react-brand'
import styles from './Video.module.css'

type VideoProps = {
  youTubeId: string
  posterImageSrc?: string
  showPlayButton?: boolean
} & React.HTMLProps<HTMLElement>

export const Video = ({
  youTubeId,
  id = `ytplayer-${youTubeId}`,
  posterImageSrc,
  showPlayButton = true,
  ...props
}: VideoProps) => {
  const startVideo = useCallback(() => {
    const iframe = document.getElementById(id) as HTMLIFrameElement | null

    const hidePlayButton = () => {
      const playButton = iframe?.nextElementSibling
      if (playButton && playButton.classList.contains(styles.VideoPlayButton)) {
        playButton.classList.add(styles['is-hidden'])
      }
    }

    if (iframe) {
      const runOnOpen = () => {
        if (iframe.contentWindow) {
          iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
        }
        hidePlayButton()
      }

      const waitForIframeLoad = () => {
        return new Promise<void>(resolve => {
          iframe.addEventListener('load', () => {
            resolve()
          })
        })
      }

      // eslint-disable-next-line github/no-then
      waitForIframeLoad().then(() => {
        runOnOpen()
      })

      runOnOpen()
    } else {
      hidePlayButton()
    }
  }, [id])

  return (
    <section className={styles.Video} {...props}>
      <iframe
        id={id}
        className={styles['Video-iframe']}
        width="560"
        height="315"
        src={`https://www.youtube-nocookie.com/embed/${youTubeId}?enablejsapi=1&rel=0`} // `rel=0` hides related videos
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      {/* Optional custom poster image */}
      {posterImageSrc && (
        <button className={styles.VideoPlayButton} aria-label="Play video" onClick={() => startVideo()}>
          <Image src={posterImageSrc} alt="" className={styles.VideoPlayPoster} />
          {showPlayButton && <Image src="/images/icon-play.svg" alt="" className={styles.VideoPlayIcon} />}
        </button>
      )}
    </section>
  )
}
