import React from 'react'
import {Button, CTABanner} from '@primer/react-brand'
import styles from './CallToAction.module.scss'
import {ROADMAP_URL} from '../../constants'

export const CallToAction = () => {
  return (
    <CTABanner align="center" hasShadow={false} className={styles['CallToAction']}>
      <CTABanner.Heading as="h2" size="3">
        Explore the possibilities of <br className="break-when544" /> GitHub for your enterprise
      </CTABanner.Heading>
      <CTABanner.Description className={styles['CallToAction-description']}>
        Curious about how GitHub can help provide the tools to modernize your organization? Check out what’s next on our
        product roadmap.
      </CTABanner.Description>
      <Button as="a" href={ROADMAP_URL} variant="primary">
        See our product roadmap
      </Button>
    </CTABanner>
  )
}
