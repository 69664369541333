import React from 'react'
import parser from 'html-react-parser'
import {FAQ, Heading} from '@primer/react-brand'
import {Column, Row} from '..'

import styles from './Faq.module.scss'

// eslint-disable-next-line import/extensions
import faqData from '../../content/faq.json' // TODO: Move out of bundle and load async

export const Faq = () => {
  return (
    <Row colRatio="40-60">
      <Column>
        <Heading as="h2" size="3" weight="semibold" className={styles.FAQ__title}>
          Frequently <br />
          Asked Questions
        </Heading>
      </Column>
      <Column>
        <FAQ className={styles.FAQ}>
          {faqData.map(({question, answers}, index) => {
            return (
              <FAQ.Item key={index + question}>
                <FAQ.Question as="h2" className={styles.FAQ__question}>
                  {question}
                </FAQ.Question>
                <FAQ.Answer>
                  {answers.map((paragraph, answerIndex) => {
                    return <p key={answerIndex + paragraph}>{parser(paragraph)}</p>
                  })}
                </FAQ.Answer>
              </FAQ.Item>
            )
          })}
        </FAQ>
      </Column>
    </Row>
  )
}
