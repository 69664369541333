import React, {HTMLAttributes} from 'react'
import {default as clsx} from 'clsx'

import styles from './Spacer.module.css'

interface SpacerProps extends HTMLAttributes<HTMLDivElement> {
  narrow?: string
  wide?: string
}

export const Spacer = ({narrow, wide, className}: SpacerProps) => {
  return (
    <div
      className={clsx(styles['Spacer'], className)}
      style={{'--Spacer-narrow': narrow, '--Spacer-wide': wide} as React.CSSProperties}
      aria-hidden
    ></div>
  )
}
