import React, {useState} from 'react'
import {Heading, Image, Text} from '@primer/react-brand'
import {Spacer} from '..'
import styles from './VideoPlaylist.module.css'

// eslint-disable-next-line import/extensions
import videos from './video-playlist.json'

export const VideoPlaylist = () => {
  const [selectedVideo, setSelectedVideo] = useState(videos[0]) // default to the first video
  const [isPlaying, setIsPlaying] = useState(false) // set when user starts playing a video

  const handleClick = (youTubeId: string): void => {
    const video = videos.find(v => v.youTubeId === youTubeId)
    if (video) {
      setSelectedVideo(video)
      playVideo()
    }
  }

  // Play video
  const playVideo = () => {
    const iframe = document.getElementById('ytplayer') as HTMLIFrameElement | null

    if (iframe) {
      const runOnOpen = () => {
        if (iframe.contentWindow) {
          iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')

          setIsPlaying(true)
          iframe.parentElement?.classList.add(styles['is-playing'])

          const rect = iframe.getBoundingClientRect()
          // scroll page to the top of the video player with an offset
          window.scrollTo({
            top: rect.top + window.scrollY - 145, // adjust the offset here, matches the scroll-margin-top + 65px
            behavior: 'smooth',
          })
        }
      }

      const waitForIframeLoad = () => {
        return new Promise<void>(resolve => {
          iframe.addEventListener('load', () => {
            resolve()
          })
        })
      }

      // eslint-disable-next-line github/no-then
      waitForIframeLoad().then(() => {
        runOnOpen()
      })

      runOnOpen()
    }
  }

  return (
    <>
      <div className={styles.Container}>
        <div className={styles.VideoPlayer}>
          <div className={styles.Video}>
            <iframe
              id="ytplayer"
              className={styles.VideoIframe}
              width="560"
              height="315"
              src={`https://www.youtube-nocookie.com/embed/${selectedVideo.youTubeId}?enablejsapi=1&rel=0`} // `rel=0` hides related videos
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              // Skip tabbing to the YouTube player if initially the custom VideoPlayButton is still visible/enabled
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex={isPlaying ? 0 : -1}
            ></iframe>

            <button
              className={styles.VideoPlayButton}
              aria-label="Play video"
              onClick={() => handleClick(selectedVideo.youTubeId)}
              disabled={isPlaying}
            >
              <Image src={selectedVideo.thumbnail} alt="" className={styles.VideoPoster} />
              <Image src="/images/icon-play.svg" alt="" className={styles.VideoPlayIcon} />
            </button>
          </div>

          <Spacer narrow="16px" wide="32px" />

          <div className={styles.VideoInfo}>
            <Heading as="h2" size="6">
              {selectedVideo.title}
            </Heading>
            <Text as="p" size="200" variant="muted" className={styles.VideoDescription}>
              {selectedVideo.description.split('\n').map((line, index, array) => (
                // Add line breaks coming from JSON (\n\n)
                <React.Fragment key={index}>
                  {line}
                  {index < array.length - 1 && <br />}
                </React.Fragment>
              ))}
            </Text>
          </div>
        </div>

        <Spacer narrow="48px" wide="96px" />

        <div className={styles.Playlist}>
          {videos.map((video, index) => (
            <button
              key={index}
              aria-label="Play video"
              onClick={() => handleClick(video.youTubeId)}
              className={`${styles.PlaylistItem} ${
                selectedVideo.youTubeId === video.youTubeId ? styles['is-selected'] : ''
              }`}
            >
              <Image src={video.thumbnail} alt="" className={styles.PlaylistThumb} />
              <Heading as="h2" size="6" className={styles.PlaylistTitle}>
                {video.title}
              </Heading>
            </button>
          ))}
        </div>
      </div>
    </>
  )
}
