import React, {useEffect, useState} from 'react'
import {ActionMenu, Button, CTABanner, Heading, Label, Stack, Text} from '@primer/react-brand'

import styles from './EventGrid.module.css'

// eslint-disable-next-line import/extensions
import eventData from '../../content/events.json'
import {addUTMParams} from '../../fn/addUTMParams'

export const EventGrid = () => {
  // Pre-select region based on user's timezone
  //
  // Might not be accurate for all users, but it's a good default

  // eslint-disable-next-line i18n-text/no-en
  let defaultRegion = 'Asia Pacific' // fallback
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  if (timeZone.startsWith('America') || timeZone.startsWith('Atlantic')) {
    defaultRegion = 'Americas'
  } else if (timeZone.startsWith('Europe') || timeZone.startsWith('Africa')) {
    defaultRegion = 'Europe, Middle East & Africa'
  }

  const [currentRegion, setCurrentRegion] = useState(defaultRegion)

  // Past events
  //
  // This is a simple way to mark past events.
  // It doesn't account for timezones, but hides only on the next day.

  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)

  const updatedEvents = eventData.events.map(event => {
    if (event.date === 'Coming soon') {
      return {...event, isPast: false} // Assuming future events are not past
    }

    const eventDate = new Date(event.date)
    eventDate.setHours(0, 0, 0, 0) // Adjusts event date to start of the day

    // Add isPast property based on comparison
    const isPast = event.region === currentRegion && eventDate < currentDate
    return {...event, isPast}
  })

  // Filter events per region

  const filteredEvents = updatedEvents.filter(event => {
    return event.region === currentRegion
  })

  // Regions
  //
  // The "Virtual" event (Global region) is shown at the bottom and not part of the grid.

  const regions = [...new Set(eventData.events.filter(event => event.region !== 'Global').map(event => event.region))]

  // UTM params
  //
  // Update UTM params when the region changes
  useEffect(() => {
    addUTMParams()
  }, [currentRegion])

  return (
    <>
      <Stack
        direction={{narrow: 'vertical', wide: 'horizontal'}}
        alignItems={{narrow: 'flex-start', wide: 'center'}}
        padding="none"
        style={{marginBottom: '48px'}}
      >
        <Heading as="h2" size="3" weight="semibold" style={{marginRight: 'auto'}}>
          Find your city
        </Heading>

        {/* Desktop */}
        <Stack direction="horizontal" padding="none" gap="none" className={styles['EventGrid-regionPicker--desktop']}>
          {regions.map(region => (
            <Button
              key={region}
              onClick={() => setCurrentRegion(region)}
              hasArrow={false}
              className={`
                ${styles['EventGrid-filterButton']}
                ${region === currentRegion ? styles['EventGrid-filterButton--selected'] : ''}
              `}
            >
              {region}
            </Button>
          ))}
        </Stack>

        {/* Mobile */}
        <div className={styles['EventGrid-regionPicker--mobile']}>
          <ActionMenu onSelect={newValue => setCurrentRegion(newValue)}>
            <ActionMenu.Button className={styles['EventGrid-regionPickerButton']}>
              Region: {currentRegion}
            </ActionMenu.Button>
            <ActionMenu.Overlay aria-label="Regions">
              {regions.map(region => (
                <ActionMenu.Item key={region} value={region} selected={region === currentRegion}>
                  {region}
                </ActionMenu.Item>
              ))}
            </ActionMenu.Overlay>
          </ActionMenu>
        </div>
      </Stack>

      <ul className={styles.EventGrid}>
        {filteredEvents.map((event, index) => (
          <li key={event.city} className={`${styles['EventGrid-item']} ${event.isPast ? styles['is-past'] : ''}`}>
            {/* Hover effect */}
            <div className={styles['EventGrid-layerBottom']}></div>
            <div className={styles['EventGrid-layerMid']}></div>
            <div className={styles['EventGrid-layerTop']}></div>

            <div className={styles['EventGrid-box']} style={{animationDelay: `${0.05 * index}s`}}>
              <Text as="div" size="200" weight="medium" className={styles['EventGrid-date']}>
                {event.date}
              </Text>

              <div>
                <Heading as="h3" size="4" weight="semibold" className={styles['EventGrid-city']}>
                  {event.city}
                </Heading>
                <Text as="div" size="200" className={styles['EventGrid-description']}>
                  {event.venue}
                </Text>
              </div>

              {!event.isPast && (
                <div className={styles['EventGrid-cta']}>
                  {(() => {
                    if (event.url) {
                      return (
                        <Button
                          as="a"
                          block
                          href={event.url}
                          variant="primary"
                          aria-label={`Register now for the ${event.city} event`}
                        >
                          Register now
                        </Button>
                      )
                    } else {
                      return (
                        <Button
                          as="a"
                          block
                          href={`/getnotified?city=${event.city}`}
                          aria-label={`Get notified about ${event.city} updates`}
                        >
                          Get notified
                        </Button>
                      )
                    }
                  })()}
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>

      <CTABanner align="center" hasShadow={false} className={styles['EventGrid-CTABanner']} style={{marginTop: '48px'}}>
        <CTABanner.Heading as="h3" size="3">
          <Label className={styles['EventGrid-CTABanner-label']}>18-20 JUNE 2024</Label>
          Join us virtually
        </CTABanner.Heading>
        <CTABanner.Description className={styles['EventGrid-CTABanner-description']}>
          Our virtual program will give you the tools to modernize your organization—from anywhere.
        </CTABanner.Description>
        <Button as="a" href="/virtual" variant="primary">
          Register now
        </Button>
      </CTABanner>
    </>
  )
}
