import {default as clsx} from 'clsx'
import React, {PropsWithChildren} from 'react'
import {Text} from '@primer/react-brand'

import styles from './Label.module.scss'

type LabelProps = PropsWithChildren<React.HTMLProps<HTMLSpanElement>> & {
  variant?: 'gradient'
}

export const Label = ({children, className, variant, ...props}: LabelProps) => {
  return (
    <span {...props} className={clsx(styles.Label, variant && styles['Label--variant-gradient'], className)}>
      <Text as="span" size="300" weight="medium">
        {children}
      </Text>
    </span>
  )
}
