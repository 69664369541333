import React from 'react'
import {MinimalFooter, ThemeProvider} from '@primer/react-brand'

import styles from './Footer.module.css'

export const Footer = () => {
  return (
    <ThemeProvider colorMode="dark">
      <MinimalFooter socialLinks={['x', 'github', 'linkedin', 'youtube', 'facebook']} className={styles.Footer}>
        <MinimalFooter.Link href="https://github.com/organizations/enterprise_plan">
          Try GitHub for free
        </MinimalFooter.Link>
        <MinimalFooter.Link href="https://github.com/enterprise">Enterprise</MinimalFooter.Link>
        <MinimalFooter.Link href="https://github.com/enterprise/contact">Email us</MinimalFooter.Link>
      </MinimalFooter>
    </ThemeProvider>
  )
}
