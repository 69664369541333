import React from 'react'
import {AnimationProvider, Stack, Text, Image} from '@primer/react-brand'
import styles from './Info.module.scss'

export const Info = () => {
  return (
    <>
      <AnimationProvider>
        <Stack
          padding="none"
          direction={{
            narrow: 'vertical',
            regular: 'horizontal',
            wide: 'horizontal',
          }}
          className={styles['Info-container']}
        >
          <div className={styles['Info-box']}>
            <Text as="p" size="400" weight="normal">
              Experience a full day of curated sessions for tech leaders interested in harnessing our AI-powered
              platform to supercharge productivity and collaboration at scale.
            </Text>
          </div>

          <hr className="vertical-rule" />

          <Stack direction="vertical" padding="none" className={styles['Info-box']}>
            <Stack direction="horizontal" padding="none" gap="spacious" alignItems="flex-start" animate="slide-in-left">
              <Image
                src="/images/theme-icon-1.svg"
                alt=""
                width={42}
                style={{objectFit: 'initial', marginBlockStart: '6px'}}
              />
              <Text size="400" weight="normal" style={{flex: '1'}}>
                Enhance developer workflows with AI and machine learning
              </Text>
            </Stack>
            <Stack direction="horizontal" padding="none" gap="spacious" alignItems="flex-start" animate="slide-in-left">
              <Image
                src="/images/theme-icon-2.svg"
                alt=""
                width={42}
                style={{objectFit: 'initial', marginBlockStart: '2px'}}
              />
              <Text size="400" weight="normal" style={{flex: '1'}}>
                Tackle technical debt in your codebase
              </Text>
            </Stack>
            <Stack direction="horizontal" padding="none" gap="spacious" alignItems="flex-start" animate="slide-in-left">
              <Image
                src="/images/theme-icon-3.svg"
                alt=""
                width={42}
                style={{objectFit: 'initial', marginBlockStart: '4px'}}
              />
              <Text size="400" weight="normal" style={{flex: '1'}}>
                Integrate security seamlessly with DevSecOps
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </AnimationProvider>
    </>
  )
}
