import React from 'react'
import {Heading, Stack, Text, Image, Button, ButtonGroup} from '@primer/react-brand'
import {ArrowDownRightIcon} from '@primer/octicons-react'
import styles from './Hero.module.css'
import {ROADMAP_URL} from '../../constants'

export const Hero = () => {
  return (
    <>
      <Heading as="h1" aria-label="Galaxy 2024">
        <Image src="/images/hero/hero-title.png" alt="" width={1216} className="Hero-title" />
      </Heading>

      <Stack
        padding="none"
        gap="normal"
        direction={{
          narrow: 'vertical',
          regular: 'horizontal',
          wide: 'horizontal',
        }}
        className={styles['Hero-box']}
      >
        <Text as="p" size="400" weight="normal" style={{flex: '1'}}>
          Gain insights from our recent global event for business leaders on AI-powered development and modernization.
        </Text>
        <ButtonGroup className="gap-16">
          <Button as="a" href="#sessions" variant="primary" trailingVisual={<ArrowDownRightIcon />}>
            Watch the sessions
          </Button>
          <Button as="a" href={ROADMAP_URL} variant="secondary" hasArrow={true}>
            See our product roadmap
          </Button>
        </ButtonGroup>
      </Stack>
    </>
  )
}
