import {default as clsx} from 'clsx'
import React, {PropsWithChildren} from 'react'

import styles from './Row.module.css'

type RowProps = {
  colRatio?: '60-40' | '50-50' | '40-60'
} & PropsWithChildren<React.HTMLProps<HTMLDivElement>>

export const Row = ({colRatio, children, className, ...props}: RowProps) => {
  return (
    <div className={clsx(styles.Row, colRatio && styles[`Row--${colRatio}`], className)} {...props}>
      {children}
    </div>
  )
}
