import React from 'react'
import Head from 'next/head'

// design system components
import {Text, Image, Hero} from '@primer/react-brand'

// generic UI components
import {Footer} from '..'

// page specific styles
import styles from './Thanks.module.css'

export function Thanks() {
  return (
    <div className={styles.homepage}>
      <Head>
        <title>Thank you - GitHub Galaxy</title>
        <meta name="robots" content="noindex,nofollow" />
      </Head>

      <main className={styles.main}>
        <section className={styles.container}>
          <Image src="/images/hero/hero-thankyou.jpg" alt="" width={650} style={{display: 'block', margin: '0 auto'}} />

          <Hero align="center" className={styles['Hero']}>
            <Hero.Heading size="2">
              Thanks for your interest <br /> in GitHub Galaxy
            </Hero.Heading>
            <Hero.Description>
              <Text size="300" variant="muted">
                We successfully received your email address! You’ll be the first to know when registration opens.
              </Text>
            </Hero.Description>
            <Hero.PrimaryAction href="/">Back to home</Hero.PrimaryAction>
          </Hero>
        </section>
      </main>

      <Footer />
    </div>
  )
}
