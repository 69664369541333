import React, {PropsWithChildren, useState, useEffect} from 'react'
import {XIcon} from '@primer/octicons-react'
import A11yDialog from 'a11y-dialog'

import styles from './Dialog.module.css'

type DialogProps = PropsWithChildren<React.HTMLProps<HTMLDivElement>> & {
  id: string
  onOpen?: () => void
  onClose?: () => void
}

export const Dialog = ({children, id, onOpen, onClose, ...props}: DialogProps) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const dialogEl = document.getElementById(id)
    if (dialogEl) {
      const dialog = new A11yDialog(dialogEl)

      dialog.on('show', function () {
        setIsOpen(true)

        if (onOpen) onOpen()
        document.documentElement.style.overflowY = 'hidden'
      })

      dialog.on('hide', function () {
        if (onClose) onClose()
        document.documentElement.style.overflowY = ''
      })
    }
  }, [onClose, onOpen, id])

  return (
    <div className={styles.Dialog__container} id={id} aria-hidden="true" {...props}>
      <div className={styles.Dialog__overlay} data-a11y-dialog-hide></div>
      <button
        type="button"
        data-a11y-dialog-hide
        className={styles.Dialog__close}
        aria-label="Close this dialog window"
      >
        <XIcon size={24} className={styles.Dialog__closeIcon} />
      </button>
      {isOpen && (
        <div className={styles.Dialog__content} role="document">
          {children}
        </div>
      )}
    </div>
  )
}
