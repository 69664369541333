import React, {PropsWithChildren} from 'react'
import {Avatar, AvatarProps, Stack, Text} from '@primer/react-brand'

import styles from './Speaker.module.css'

type SpeakerProps = PropsWithChildren<React.HTMLProps<HTMLDivElement>> & {
  name: string
  position: string
  imageUrl: string
  avatarStyle?: 'circle' | 'square'
  avatarSize?: AvatarProps['size']
}

export const Speaker = ({name, position, imageUrl, avatarStyle = 'circle', avatarSize = 48}: SpeakerProps) => {
  return (
    <Stack direction="horizontal" padding="none" alignItems="center">
      <div className={styles.avatar}>
        <Avatar
          size={avatarSize}
          src={imageUrl}
          alt={`${name} avatar`}
          shape={avatarStyle}
          loading="lazy"
          decoding="async"
        />
      </div>

      <Stack direction="vertical" padding="none" gap="none">
        <Text as="p" size="300" weight="semibold">
          {name}
        </Text>
        <Text as="p" variant="muted" size="300">
          {position}
        </Text>
      </Stack>
    </Stack>
  )
}
