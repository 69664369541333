import {default as clsx} from 'clsx'
import React, {PropsWithChildren} from 'react'
import {Text} from '@primer/react-brand'
import {SparkleFillIcon} from '@primer/octicons-react'

import styles from './ListItem.module.css'

type ListItemProps = {
  highlightedText: string
} & PropsWithChildren<React.HTMLProps<HTMLLIElement>>

export const ListItem = ({children, highlightedText, ...props}: ListItemProps) => {
  return (
    <li className={clsx(styles.ListItem)} {...props}>
      <span className={styles['ListItem__leading-visual']}>
        <SparkleFillIcon />
      </span>
      <Text size="300">
        <Text className={styles['ListItem__highlighted-text']} weight="semibold" as="strong" size="300">
          {highlightedText}:{' '}
        </Text>
        <Text size="300" weight="medium">
          {children}
        </Text>
      </Text>
    </li>
  )
}
