import React, {useState} from 'react'
import {ActionMenu, Button, Heading, Label, Link, Stack, Text, Timeline} from '@primer/react-brand'

import styles from './EventList.module.css'

// eslint-disable-next-line import/extensions
import eventData from './events.json'

export const EventList = () => {
  const [filter, setFilter] = useState('All') // default filter

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addIsPastToEvents = (events: any[]) => {
    return events.map(event => {
      const eventDate = new Date(event.date)
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)
      eventDate.setHours(0, 0, 0, 0)

      return {
        ...event,
        isPast: eventDate < currentDate,
      }
    })
  }

  const eventDataWithIsPast = {
    ...eventData,
    events: addIsPastToEvents(eventData.events),
  }

  const filteredEvents =
    filter === 'All'
      ? eventDataWithIsPast.events
      : eventDataWithIsPast.events.filter(event => event.region === filter || event.region === 'Global')

  const regions = ['All', ...new Set(eventData.events.map(event => event.region))]

  return (
    <>
      <Stack direction="horizontal" padding="none" style={{marginBottom: '48px'}}>
        <Heading as="h2" size="4" weight="semibold" style={{marginRight: 'auto'}}>
          Regional Events
        </Heading>

        <ActionMenu onSelect={newValue => setFilter(newValue)} menuAlignment="end">
          <ActionMenu.Button>Region: {filter}</ActionMenu.Button>
          <ActionMenu.Overlay aria-label="Regions">
            {regions
              .filter(region => region !== 'Global')
              .map(region => (
                <ActionMenu.Item key={region} value={region} selected={region === filter}>
                  {region}
                </ActionMenu.Item>
              ))}
          </ActionMenu.Overlay>
        </ActionMenu>
      </Stack>

      <Timeline className={styles.EventList}>
        {filteredEvents.map((event, index) => (
          <Timeline.Item
            key={index}
            className={`
              ${styles['EventList-item']}
              ${event.isPast ? styles['EventList-item--past'] : ''}
              ${event.region === 'Global' ? styles['EventGrid-item--highlighted'] : ''}
            `}
          >
            <Text size="300" weight="medium" className={styles['EventList-date']}>
              {stripYearFromDate(event.date)}
            </Text>
            <Text size="300" weight="medium" className={styles['EventList-city']}>
              {event.city} {event.emoji}
            </Text>
            <Text size="200" variant="muted" className={styles['EventList-description']}>
              {event.description}
            </Text>
            <div className={styles['EventList-cta']}>
              {(() => {
                if (event.recapUrl) {
                  return <Link href={event.recapUrl}>Watch recap</Link>
                } else if (event.isPast) {
                  return ''
                } else if (event.isSoldOut) {
                  return <Label>Sold out</Label>
                } else if (event.url) {
                  return (
                    <Button as="a" href={event.url} variant="primary">
                      Register
                    </Button>
                  )
                } else {
                  return (
                    <Button as="a" href={`/getnotified?city=${event.city}`}>
                      Get notified
                    </Button>
                  )
                }
              })()}
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </>
  )

  function stripYearFromDate(dateString: string): string {
    if (dateString === 'Coming soon') {
      return dateString
    }

    const date = new Date(dateString)
    const day = date.getDate()
    const month = date.toLocaleString('default', {month: 'long'}) // getMonth() is zero-based

    return `${month} ${day}`
  }
}
